
.recording-circle {
    border-radius: 50%;
}

.pulse {
    animation: ease pulse 2s infinite;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}
