
.tooltip-arrow {
    background: inherit;
}

.tooltip-arrow::before {
    content: "";
    background: inherit;
    width: 0.4rem;
    height: 0.4rem;
    transform: translate(-50%, -50%) rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
}

.tooltip[data-popper-placement^='top'] > .tooltip-arrow {
    bottom: 0px;
}

.tooltip[data-popper-placement^='bottom'] > .tooltip-arrow {
    top: 0px;
}

.tooltip[data-popper-placement^='left'] > .tooltip-arrow {
    right: 0px;
}

.tooltip[data-popper-placement^='right'] > .tooltip-arrow {
    left: 0px;
}
