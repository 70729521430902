/***** Scrollbar always shown to prevent jumping page bug *****/

html {
    overflow-y: scroll;
}

/***** Placeholders *****/

.placeholder-custom::placeholder {
    color: inherit;
    opacity: 0.4;
}

/***** Input number *****/

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/***** Disable hover on mobile devices *****/

@media (pointer: fine) {
    .opacity-50-on-devices-supporting-hover:hover {
        opacity: 0.5 !important;
    }
}

/***** React select *****/

.bg-black-important {
    background: black !important;
}

.bg-transparent-important {
    background: transparent !important;
}

.bg-inherit-important {
    background: inherit !important;
}

.border-0-important {
    border: none !important;
}

.color-inherit-important {
    color: inherit !important;
}

.cursor-pointer-important {
    cursor: pointer !important;
}

.descendent-color-inherit-important * {
    color: inherit !important;
}

.descendent-bg-transparent-important * {
    background: transparent !important;
}

.descendent-p-0-important > * {
    padding: 0 !important;
}

.flex-important {
    display: flex !important;
}

.hidden-important {
    display: none !important;
}

.mt-1-important {
    margin-top: 0.25rem !important;
}

.p-0-important {
    padding: 0 !important;
}

.rounded-menu-important {
    border-radius: 0.3rem !important;
}

.shadow-none-important {
    box-shadow: none !important;
}

.min-h-32px-important {
    min-height: 32px !important;
}

.z-40-important {
    z-index: 40 !important;
}

/***** React datepicker *****/

.react-datepicker__close-icon::after {
    color: inherit !important;
    background-color: inherit !important;
}

.react-datepicker__month-container {
    background-color: inherit !important;
    border-color: inherit !important;
}

.react-datepicker__header {
    background-color: inherit !important;
    border-color: inherit !important;
}

.react-datepicker__triangle {
    border-color: inherit !important;
}

.react-datepicker__triangle::after {
    border-bottom-color: inherit !important;
}

.react-datepicker__current-month {
    color: inherit !important;
}

.react-datepicker__day-name {
    color: inherit !important;
}

.react-datepicker__navigation {
    border-color: inherit !important;
}

.react-datepicker__navigation:hover {
    opacity: 0.5;
}

.react-datepicker__navigation-icon {
    border-color: inherit !important;
}

.react-datepicker__navigation-icon::before {
    border-color: inherit !important;
}

.react-datepicker__day {
    background-color: inherit !important;
    color: inherit !important;
}

.react-datepicker__day:hover {
    opacity: 0.5;
}

.react-datepicker__day--selected {
    outline: none !important;
}

/***** Wide elements *****/

.max-w-fit-content {
    max-width: -moz-available;
    max-width: -webkit-fill-available;
    max-width: -fill-available;
}

/***** Blue rectangle on mobiles *****/

* { -webkit-tap-highlight-color: rgba(0,0,0,0); }

/***** 100vh in pixels measurement *****/

#artificial-element-with-100vh-height {
    height: 100vh;
    width: 0;
    position: absolute;
}

/***** Appropriate cursor on wavesurfer chart in  not selected AudioPanels *****/

.not-selected-audio-panel wave {
    cursor: pointer !important;
}
